<template>
  <UiDrawer persistent v-model="opened">
    <div class="p-2 h-[calc(90dvh)] overflow-auto pb-10">
      <div class="max-w-[600px] mx-auto">
        <div
          class="leading-none mb-1 font-serif text-[24px] text-center flex justify-center items-center"
        >
          <span class="whitespace-nowrap">respostaCerta</span>
          <span class="text-primary ml-2">PREMIUM</span>
          <img
            src="/images/icons/star.png"
            class="min-w-[28px] h-[28px] opacity-80 ml-2"
          />
        </div>
        <p class="text-[18px] text-center">
          Libere todos os recursos da plataforma
        </p>

        <ul class="mt-4 grid gap-2 text-[18px] font-medium">
          <li>✅ Acesso ilimitado as resoluções das questões</li>
          <li>✅ Resolva quantas questões quiser no dia</li>
          <li>✅ Veja sua nota detalhada por competência</li>
          <li>✅ Treine com questões por competência</li>
        </ul>

        <div class="mt-4 font-medium">Escolha um plano:</div>

        <div class="mt-2 grid gap-2">
          <div
            class="relative border-2 cursor-pointer rounded-[14px] p-4 flex items-center"
            @click="plan = 'annual'"
            :class="{
              'border-primary text-primary': plan === 'annual',
              'border-accent/60': plan !== 'annual',
            }"
          >
            <div class="font-bold text-[20px]">✓</div>
            <div class="font-serif text-[24px] ml-2">Anual</div>
            <div class="ml-auto text-right text-black">
              <div class="font-bold tracking-wide">R$ 7,70 / mês</div>
              <div class="text-[14px] text-accent">Total de R$ 92,4</div>
            </div>

            <div
              class="absolute right-4 text-white px-4 text-[14px] top-0 -translate-y-1/2 bg-primary rounded-[4px]"
            >
              Melhor preço
            </div>
          </div>

          <div
            class="border-2 border-accent/60 cursor-pointer rounded-[14px] p-4 flex items-center"
            @click="plan = 'monthly'"
            :class="{
              'border-primary text-primary': plan === 'monthly',
              'border-accent/60': plan !== 'monthly',
            }"
          >
            <div class="font-bold text-[20px]">✓</div>
            <div class="font-serif text-[24px] ml-2">Mensal</div>
            <div class="ml-auto text-right text-black">
              <div class="font-bold tracking-wide">R$ 12,70</div>
              <div class="text-[14px] text-accent">Por mês</div>
            </div>
          </div>
        </div>

        <UiButton
          @click="createSession"
          :loading="loading"
          size="lg"
          class="mt-10"
        >
          <div class="flex flex-col">
            <span>Assinar</span>
          </div>
        </UiButton>
        <!-- <UiButton
        @click="opened = false"
        class="mt-2"
        size="sm"
        variant="flat"
        bg-color="white"
      >
        Não, obrigado
      </UiButton> -->
      </div>
    </div>
  </UiDrawer>
</template>

<script lang="ts" setup>
import { sendPlausibleEvent } from "~/libs/plausible";
import { toast } from "../toast";
import { UiDrawer, UiButton } from "../ui";

const plan = ref<"annual" | "monthly">("annual");

const { opened, loading } = storeToRefs(usePaymentStore());

watch(opened, (value) => {
  if (value) {
    plan.value = "annual";

    sendPlausibleEvent("Modal Pagamento - Aberto");
  }
});

const createSession = async () => {
  const apiClient = useApi();

  loading.value = true;
  const res = await apiClient.payment.checkout.$get({
    query: {
      plan: plan.value,
    },
  });

  if (!res.ok) {
    toast.error("Erro ao criar sessão de pagamento. Tente novamente.");
    loading.value = false;
    return;
  }

  const resValue = await res.json();

  if (resValue.error) {
    toast.error("Erro ao criar sessão de pagamento. Tente novamente.");
    loading.value = false;
    return;
  }

  if (resValue.checkoutUrl) {
    sendPlausibleEvent(`Modal Pagamento - Checkout Aberto(${plan.value})`);

    window.location.href = resValue.checkoutUrl;
  }
};
</script>
